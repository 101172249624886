import { useState } from "react"
import { OrderTracking } from "../models/OrderTracking"
import { Incident, INCIDENT_LABELS } from "../models/TrackingHistory"
import { useIntl } from "react-intl"
import clsx from "clsx"
import { Dropdown } from "react-bootstrap"
import { toast } from "react-toastify"

const MAX_COMMENT_LENGTH = 560

export default function OrderTrackingDetail({ 
    orderTracking, 
    onCommentAdded, 
    onChangeIncident,
    singleOrderDisplay = false
}: { 
    orderTracking: OrderTracking, 
    onCommentAdded: (comment: string) => Promise<void>
    onChangeIncident: (incident: Incident) => Promise<void>
    singleOrderDisplay?: boolean
}) {
    const intl = useIntl()
    const [open, setOpen] = useState(false || singleOrderDisplay)
    const [comment, setComment] = useState('')
    const [submittingComment, setSubmittingComment] = useState(false)
    const [changingIncident, setChangingIncident] = useState(false)
    
    async function onAddComment() {
        try {
            setSubmittingComment(true);
            onCommentAdded && await onCommentAdded(comment);      
            setComment('');
        } catch (err) {
            console.warn(err);
        } finally {
            setSubmittingComment(false);
        }
    }

    async function onIncidentChanged(incident: Incident) {
        try {
            setChangingIncident(true);
            onChangeIncident && await onChangeIncident(incident);
        } catch (err) {
            console.warn(err);
        } finally {
            setChangingIncident(false);
        }
    }

    async function copyToClipboard(text) {
        try {
          if (navigator?.clipboard) {
            navigator.clipboard.writeText(text)
          } else {
            var textField = document.createElement('textarea')
            textField.textContent = text
            document.body.appendChild(textField)
            textField.select()
            document?.execCommand('copy')
            textField.remove()
          }
          toast.success(intl.formatMessage({id: 'shopifyOrders.copiedToClipboard'}))
        } catch (error) {
          console.warn({error})
          toast.error(intl.formatMessage({id: 'shopifyOrders.copyToClipboardError'}))
        }
      }

    return <article
        className={clsx({
            'table-row border-bottom-dashed border-gray-200': true,
        })}>
        <details open={open} className="my-0 py-0" >
            <summary 
                className="d-flex justify-content-between align-items-center pt-2 pb-3 gap-2"
                onClick={(e) => {
                    e.preventDefault();
                    if (!singleOrderDisplay) {
                        setOpen(!open)
                    }
                }}
            >
                <div className="w-200px d-flex flex-column align-items-start gap-1">
                    <div className="fw-bolder">{orderTracking.code}</div>
                    { !!orderTracking.tracking_history?.shipping_tracking && <div className="badge badge-info">
                        <span className="fa fa-shipping-fast me-2"></span>
                        {orderTracking.tracking_history?.shipping_tracking}
                    </div> }
                    <div className="badge badge-dark">{orderTracking.kind}</div>
                    <div className="badge badge-primary">{orderTracking.state}</div>
                </div>
                <div className="w-200px text-start d-flex flex-column align-items-start justify-content-evenly gap-1">
                        <Dropdown onClick={(e) => e.stopPropagation()}>
                            <Dropdown.Toggle
                                disabled={changingIncident}
                                id={`dropdown-incident-${orderTracking.code}`}
                                variant='secondary'
                                className={clsx({
                                    'badge badge-sm px-4 py-2 mb-0': true,
                                    'bg-warning': !!orderTracking?.tracking_history?.incident,
                                })}
                            >
                                {INCIDENT_LABELS[orderTracking?.tracking_history?.incident || '']}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                Object.values(Incident).map((incident) => (
                                    <Dropdown.Item
                                        className='p-4'
                                        key={incident}
                                        onClick={() => onIncidentChanged(incident)}
                                        disabled={changingIncident}
                                    >
                                        {INCIDENT_LABELS[incident]}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    <span className="fs-8">
                        <span className="badge badge-secondary px-2 py-1 me-1">
                            {orderTracking?.tracking_history?.tracking_history_stages?.length || 0}
                        </span>
                        {intl.formatMessage({ id: 'orderTrackingHistory.stages' })}
                    </span>
                    <span className="fs-8">
                        <span className="badge badge-secondary px-2 py-1 me-1">
                            {orderTracking?.tracking_history?.tracking_history_comments?.length || 0}
                        </span>
                        {intl.formatMessage({ id: 'orderTrackingHistory.comments' })}
                    </span>
                </div>
                { !singleOrderDisplay && <div className="w-200px text-start ps-4">
                    {
                        orderTracking.promise_date ?
                            intl.formatDate(new Date(orderTracking.promise_date), {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                            : '-'
                    }
                </div> }
                { !singleOrderDisplay &&  <div className="w-200px text-start ps-4">
                    {
                        orderTracking.confirmed_at ?
                            intl.formatDate(new Date(orderTracking.confirmed_at), {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                            : '-'
                    }
                </div> }
                <div className="w-200px text-start ps-4">
                    {
                        orderTracking.dispatched_at ?
                            intl.formatDate(new Date(orderTracking.dispatched_at), {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                            : '-'
                    }
                </div>
                <div className="w-250px text-start ps-4">
                    {
                        orderTracking?.tracking_history?.tracking_link ?
                            <div className="d-flex flex-column gap-2">
                                <input
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                    type="text"
                                    value={orderTracking?.tracking_history?.tracking_link}
                                    className="form-control form-control-solid fs-8 py-1 px-3 w-100 text-truncate"
                                    readOnly
                                />
                                <div className="d-flex gap-2 justify-content-between">
                                    <button 
                                        className="btn btn-primary btn-sm flex-1 w-100 py-2 px-2 d-flex flex-nowrap align-items-center justify-content-center"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            copyToClipboard(orderTracking?.tracking_history?.tracking_link)
                                        }}
                                    >
                                        <span className="fa fa-copy me-2"></span>
                                        {
                                            intl.formatMessage({ id: 'orderTrackingHistory.copy' })
                                        }
                                    </button>
                                    <a 
                                        onClick={(e) => e.stopPropagation()}
                                        className="btn btn-primary btn-sm flex-1 w-100 py-2 px-2 d-flex flex-nowrap align-items-center justify-content-center"
                                        href={orderTracking?.tracking_history?.tracking_link} 
                                        target="_blank" 
                                        rel="noreferrer">
                                        <span className="fa fa-external-link-alt me-2"></span>
                                        {
                                            intl.formatMessage({ id: 'orderTrackingHistory.open' })
                                        }
                                    </a>
                                </div>
                            </div>
                            : '-'
                    }
                </div>
                { !singleOrderDisplay && <div className="w-100px text-center">
                    {
                        open ?
                            <span className="bi bi-chevron-up"></span>
                            : <span className="bi bi-chevron-down"></span>
                    }
                </div> }
            </summary>
            <main className="pt-8 pb-10 d-flex gap-20">
                <section className="w-400px">
                    <h2>
                        {intl.formatMessage({ id: 'orderTrackingHistory.stages' })}
                    </h2>
                    {
                        orderTracking?.tracking_history?.tracking_history_stages?.length > 0 &&
                        <section className='timeline mt-8 mb-4'>
                            {
                                orderTracking?.tracking_history?.tracking_history_stages?.map((stage) => {
                                    return (
                                        <div className='timeline-item'>
                                            <div className='timeline-line w-40px'></div>
                                            <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                                                <div className='symbol-label bg-light text-gray-400'>
                                                    <span className='fa fa-map-marker-alt fs-2'></span>
                                                </div>
                                            </div>
                                            <div className='timeline-content mb-1 mt-n1'>
                                                <div className='pe-3 mt-0 mb-4'>
                                                    <p className='fw-bolder fs-4 mb-0'>
                                                        {stage.state_description}
                                                    </p>
                                                    <p className='mb-0'>
                                                        {
                                                            stage.n_attemps > 0 && `Intentos: ${stage.n_attemps}`
                                                        }
                                                        {
                                                            stage.n_attemps > 0 && stage.delivered_to && '・'
                                                        }
                                                        {
                                                            stage.delivered_to && `Entregado a: ${stage.delivered_to}`
                                                        }
                                                    </p>
                                                    <p className='text-muted'>
                                                        {intl.formatDate(new Date(stage.created_at), {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </section>
                    }
                    {
                        !orderTracking?.tracking_history?.tracking_history_stages?.length && <p className="text-muted">
                            {intl.formatMessage({ id: 'orderTrackingHistory.noStages' })}
                        </p>
                    }
                </section>
                <section className="w-400px">
                    <h2>
                        {intl.formatMessage({ id: 'orderTrackingHistory.comments' })}
                    </h2>
                    {
                        !orderTracking?.tracking_history?.tracking_history_comments?.length && <p className="text-muted">
                            {intl.formatMessage({ id: 'orderTrackingHistory.noComments' })}
                        </p>
                    }
                    {
                        orderTracking?.tracking_history?.tracking_history_comments?.length > 0 && <div 
                            className="w-100 mt-8 d-flex gap-3 flex-column align-items-start ">
                            {
                                orderTracking?.tracking_history?.tracking_history_comments?.map((comment) => <div
                                    className="w-100 d-block "
                                    key={comment.id}>
                                    <div
                                        className="d-inline-block bg-light-primary rounded border-primary border border-dashed p-4 flex-wrap"
                                    >
                                        {comment.comment}
                                    </div>
                                    <span className="text-muted mt-1 d-block fs-8">
                                        {
                                            (comment?.author_agent?.name || comment?.author_agent?.email) ?
                                                comment?.author_agent?.name || comment?.author_agent?.email
                                                : intl.formatMessage({ id: 'orderTrackingHistory.unknownAuthor' })
                                        } ・ {  
                                            intl.formatDate(new Date(comment.commented_at), {
                                                year: '2-digit',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            })
                                        }
                                    </span>
                                </div>)
                            }
                        </div>
                    }
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        onAddComment();
                    }} className="d-flex flex-column gap-4 mt-4">
                        <input
                            disabled={submittingComment}
                            className="form-control form-control-solid w-100"
                            placeholder={intl.formatMessage({ id: 'orderTrackingHistory.placeholder' })}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            maxLength={MAX_COMMENT_LENGTH}
                        />
                        <button
                            className="btn btn-primary text-nowrap"
                            disabled={!comment || submittingComment}
                            type="submit"
                        >
                            {
                                submittingComment ?
                                    <span className="spinner w-15px h-15px spinner-border me-2"></span>
                                    : <span className="fa fa-comment me-2 "></span>
                            }
                            {intl.formatMessage({ id: 'orderTrackingHistory.addComment' })}
                        </button>
                    </form>
                </section>
            </main>
        </details>
    </article>
}