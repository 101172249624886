export enum PaymentMethodEnum {
  WIRE_TRANSFER = 'wire-transfer',
  BANK_DRAFT = 'bank-draft',
}

export interface DistributorModel {
  id: number
  name: string
  vat_number: string
  email: string
  phone: string
  state: string
  taxes: Array<number>
  payment_method?: PaymentMethodEnum
  max_payment_days?: number
  is_non_payer?: boolean
}
