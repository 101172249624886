import axios from 'axios'
import { ShopifyOrder } from '../models/ShopifyOrder'

const API_URL = process.env.REACT_APP_API_URL

interface ListShopifyOrdersProps {
    state?: string
    since?: string
    until?: string
    page?: number
    shippingService?: number
}

interface PaginatedShopifyOrders {
    count: number
    next: string
    previous: string
    results: Array<ShopifyOrder>
}

const SHOPIFY_ORDERS_PER_PAGE = 20

export function listShopifyOrders({
    state,
    since,
    until,
    shippingService,
    page = 1,
}: ListShopifyOrdersProps): Promise<{data: PaginatedShopifyOrders}> {
    const endpoint = `${API_URL}/api/shopify-orders/`
    const params = {
        state,
        promise_date__gte: since,
        promise_date__lte: until,
        shippingservice_id: shippingService,
        offset: (page - 1) * SHOPIFY_ORDERS_PER_PAGE,
        limit: SHOPIFY_ORDERS_PER_PAGE,
    }

    return axios.get(endpoint, {params})
}

export enum BulkActionsEnum {
    GENERATE_PICKING_SHEET = 'generate_picking_sheet',
    GENERATE_WAREHOUSE_OPERATIONS_SHEET = 'generate_warehouse_operations_sheet',
    PRINT_TAGS = 'print_tags',
    SET_PROCESSING = 'set_processing',
}

interface BulkActionProps {
    action: BulkActionsEnum
    promise_date__gte: string
    promise_date__lte: string
    shippingService?: number
    state: string
    orders: Array<number>
}
export function performBulkAction({
    action,
    promise_date__gte,
    promise_date__lte,
    shippingService,
    state,
    orders,
}: BulkActionProps) {
    const endpoint = `${API_URL}/api/shopify-orders/massive-actions/`

    return axios.post(endpoint, {
        action,
        promise_date__gte,
        promise_date__lte,
        shippingservice_id: shippingService,
        state,
        orders,
    })
}

export function importProduct(productId: string) {
    const endpoint = `${API_URL}/api/additional-products/import-shopify-product/`
    return axios.post(endpoint, {
        product_id: productId,
    })
}