import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Link, useHistory} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'

import './PlanTable.css'
import * as planRedux from '../redux/PlanRedux'
import {PlanListingModel} from '../models/PlanListingModel'
import {petsSelector} from '../../pets'
import * as userRedux from './../../user-profile/redux/UserRedux'

type Props = {
  selectedPetOnly?: boolean
}

export default function PlanTable({selectedPetOnly = false}: Props) {
  const displayPetName = !selectedPetOnly
  const intl = useIntl()
  const petPlans = useSelector(planRedux.petPlansSelector, shallowEqual)
  const plans = useSelector(planRedux.plansSelector, shallowEqual)
  const pets = useSelector(petsSelector, shallowEqual)
  const history = useHistory()
  const currentUser = useSelector(userRedux.currentUserSelector, shallowEqual)

  function setSelectedPlan(plan: PlanListingModel) {
    history.push(`/user/${currentUser.id}/plan/${plan?.id}`)
  }

  function getPlanList() {
    return selectedPetOnly ? petPlans : plans
  }

  if (!getPlanList().length && selectedPetOnly) {
    return <p>{intl.formatMessage({id: 'plans.noPlansForSelectedPet'})}</p>
  }

  if (!getPlanList().length && !selectedPetOnly) {
    return <p>{intl.formatMessage({id: 'plans.noPlans'})}</p>
  }

  return (
    <div className='table-responsive'>
      <table className='table  table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
        <thead className='table-header sticky-top bg-white'>
          <tr className='fw-bold'>
            <th className='w-90px'>{intl.formatMessage({id: 'plans.code'})}</th>
            {displayPetName && <th className='w-80px'>{intl.formatMessage({id: 'plans.pet'})}</th>}
            <th className=''>{intl.formatMessage({id: 'plans.state'})}</th>
            <th className=''>{intl.formatMessage({id: 'plans.pricingModel'})}</th>
            <th className=''>{intl.formatMessage({id: 'plans.defaultMealPrice'})}</th>
            <th className=''>{intl.formatMessage({id: 'plans.summary'})}</th>
            <th className='text-center'>{intl.formatMessage({id: 'plans.isComplementary'})}</th>
            <th className='text-center'>{intl.formatMessage({id: 'plans.days'})}</th>
            <th className='text-center'>{intl.formatMessage({id: 'plans.date'})}</th>
            <th className=''>{intl.formatMessage({id: 'plans.defaultGM'})}</th>
          </tr>
        </thead>
        <tbody>
          {getPlanList().map((plan: PlanListingModel) => (
            <tr key={plan.id} className='text-nowrap'>
              <td>
                <a
                  onClick={() => setSelectedPlan(plan)}
                  className='link cursor-pointer position-relative'
                >
                  {plan?.from_ftk && (
                    <img
                      src='/media/logos/frankie.jpg'
                      alt='Frankie the King'
                      className='h-20px w-20px rounded-2 place-self-center me-1 mb-1'
                    />
                  )}
                  {plan?.code}
                </a>
              </td>
              {displayPetName && (
                <td className='text-nowrap'>
                  <Link
                    to={`/user/${currentUser?.id}/pet/${
                      pets.find((pet) => pet.id === plan.pet_id)?.id
                    }`}
                    className='link cursor-pointer'
                  >
                    {pets.find((pet) => pet.id === plan.pet_id)?.name}
                  </Link>
                </td>
              )}
              <td className=''>
                <span
                  className={clsx(
                    'badge',
                    plan.state === 'active' && 'badge-success',
                    plan.state === 'proposal' && 'badge-warning text-dark',
                    plan.state !== 'active' && plan.state !== 'proposal' && 'badge-danger'
                  )}
                >
                  {plan.state}
                </span>
              </td>
              <td className='text-center'>
                <span className={clsx('badge badge-primary', plan.pricing_model === 'old' && 'badge-info')}>
                  {plan.pricing_model === 'old' && intl.formatMessage({id: 'plans.old'})}
                  {plan.pricing_model === 'new-2023' && intl.formatMessage({id: 'plans.new'})}
                </span>
              </td>
              <td className='pe-8 text-end'>
                {intl.formatNumber(plan.default_meal_price, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </td>
              <td className='pt-0 pb-1 text-nowrap summary'>
                <div className='py-4' dangerouslySetInnerHTML={{__html: plan.summary}}></div>
              </td>
              <td className='text-center'>
                {plan.combine
                  ? intl.formatMessage({id: 'plans.yes'})
                  : intl.formatMessage({id: 'plans.no'})}
              </td>
              <td className='text-center'>{plan.period.period}</td>
              <td className='text-center'>
                {new Date(plan.created_at).toLocaleDateString()}
              </td>
              <td className='pe-4'>
                {plan?.default_meal_gross_margin > 0
                  ? intl.formatNumber(plan?.default_meal_gross_margin * 100, {
                      maximumFractionDigits: 2,
                    })
                  : '0'}
                %
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
