import { ReactNode, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Dropdown } from 'react-bootstrap'
import { useIntl } from 'react-intl'

import { OrderModel, OrderStateEnum } from '../models/OrderModel'
import { DistributorModel, listDistributors } from '../../distributors'
import { SaleableProductModel } from '../models/SaleableProductModel'
import { toast } from 'react-toastify'
import { Pagination } from '@mui/material'
import { ORDERS_PER_PAGE } from '../services/OrderCRUD'

const NEUTRAL_STATES = [
  OrderStateEnum.PROPOSAL,
  OrderStateEnum.CONFIRMED,
  OrderStateEnum.PROCESSING,
  OrderStateEnum.LOCKED,
]
const SUCCESS_STATES = [OrderStateEnum.SHIPPED, OrderStateEnum.COMPLETED]
const FAIL_STATES = [OrderStateEnum.CANCELLED, OrderStateEnum.RETURNED, OrderStateEnum.UNDELIVERED]
const STATE_TRANSITIONS = {
  [OrderStateEnum.PROPOSAL]: [OrderStateEnum.CANCELLED, OrderStateEnum.CONFIRMED],
  [OrderStateEnum.CONFIRMED]: [OrderStateEnum.LOCKED, OrderStateEnum.CANCELLED],
  [OrderStateEnum.LOCKED]: [
    OrderStateEnum.PROCESSING,
    OrderStateEnum.CANCELLED,
    OrderStateEnum.COMPLETED,
    OrderStateEnum.PARTIALLY_COMPLETED,
  ],
  [OrderStateEnum.PROCESSING]: [
    OrderStateEnum.COMPLETED,
    OrderStateEnum.PARTIALLY_COMPLETED,
    OrderStateEnum.UNDELIVERED,
  ],
  [OrderStateEnum.PARTIALLY_COMPLETED]: [
    OrderStateEnum.COMPLETED,
    OrderStateEnum.UNDELIVERED,
  ],
}
type BULK_STATE = 'loading' | 'idle'

type PropType = {
  onCreateOrder: () => void
  onParseEdi: () => void
  onEditOrder: (order: OrderModel) => void
  onEditComments: (order: OrderModel) => void
  onEditLines: (order: OrderModel) => void
  onGenerateProforma: (order: OrderModel) => void
  onGenerateDeliveryNote: (order: OrderModel) => void
  onUpdateState: (order: OrderModel, state: OrderStateEnum) => void
  onBulkGenerateWarehouseOperationsSheet: (orderIds: Array<number>) => void
  onBulkGenerateProductsNecessitySheet: (orderIds: Array<number>) => void
  onBulkPrintTags: (orderIds: Array<number>) => void
  onBulkPrepareOrders: (orderIds: Array<number>) => void
  orders: Array<OrderModel>
  page: number
  count: number
  onPageChange: (page: number) => void
  products: Array<SaleableProductModel>
  loading: boolean
  children?: ReactNode
  displayBulkActions?: boolean
}

export default function OrderList({
  onCreateOrder,
  onParseEdi,
  onEditOrder,
  onEditLines,
  onEditComments,
  onGenerateProforma,
  onGenerateDeliveryNote,
  onUpdateState,
  onBulkGenerateWarehouseOperationsSheet,
  onBulkPrintTags,
  onBulkPrepareOrders,
  onBulkGenerateProductsNecessitySheet,
  onPageChange,
  orders,
  page,
  count,
  loading,
  children,
  displayBulkActions = false,
}: PropType) {
  const intl = useIntl()
  const [distributors, setDistributors] = useState<Array<DistributorModel>>([])
  const [selectedOrders, setSelectedOrders] = useState([])
  const [bulkState, setBulkState] = useState<BULK_STATE>('idle')
  const hasSelectedAllOrders = selectedOrders?.length === orders?.length
  const selectedCount = hasSelectedAllOrders ? count : selectedOrders.length

  async function fetchDistributors() {
    try {
      const { data } = await listDistributors('active')
      setDistributors(data)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    fetchDistributors()
  }, [])

  useEffect(() => {
    setSelectedOrders([])
  }, [orders])

  async function onGenerateProductsNecessitySheet() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'operationOrders.bulkGenerateProductsNecessitySheetConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkGenerateProductsNecessitySheet([])
      } else {
        await onBulkGenerateProductsNecessitySheet(selectedOrders?.map((order) => order.id))
      }

      toast.success(
        intl.formatMessage(
          { id: 'operationOrders.bulkGenerateProductsNecessitySheetSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(
        intl.formatMessage({ id: 'operationOrders.bulkGenerateProductsNecessitySheetError' })
      )
    } finally {
      setBulkState('idle')
    }
  }

  async function onGenerateWarehouseOperationsSheet() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'operationOrders.bulkGenerateWarehouseOperationsSheetConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkGenerateWarehouseOperationsSheet([])
      } else {
        await onBulkGenerateWarehouseOperationsSheet(selectedOrders?.map((order) => order.id))
      }

      toast.success(
        intl.formatMessage(
          { id: 'operationOrders.bulkGenerateWarehouseOperationsSheetSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(
        intl.formatMessage({ id: 'operationOrders.bulkGenerateWarehouseOperationsSheetError' })
      )
    } finally {
      setBulkState('idle')
    }
  }

  async function onPrintTags() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'operationOrders.bulkPrintTagsConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {

      if (hasSelectedAllOrders) {
        await onBulkPrintTags([])
      } else {
        await onBulkPrintTags(selectedOrders?.map((order) => order.id))
      }

      toast.success(
        intl.formatMessage(
          { id: 'operationOrders.bulkPrintTagsSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'operationOrders.bulkPrintTagsError' }))
    } finally {
      setBulkState('idle')
    }
  }

  async function onPrepareOrders() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'operationOrders.bulkPrepareOrdersConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkPrepareOrders([])
      } else {
        await onBulkPrepareOrders(selectedOrders?.map((order) => order.id))
      }
      
      toast.success(
        intl.formatMessage(
          { id: 'operationOrders.bulkPrepareOrdersSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(intl.formatMessage({ id: 'operationOrders.bulkPrepareOrdersError' }))
    } finally {
      setBulkState('idle')
    }
  }

  return (
    <>
      <div className='card p-10 h-auto'>
        <div className='d-flex align-items-center justify-content-between mb-8'>
          <h2 className='card-title'>{intl.formatMessage({ id: 'b2bOrders.title' })}</h2>
          <Dropdown>
            <Dropdown.Toggle variant='primary' id='dropdown-create-b2b-order'>
              <span className='fa fa-plus me-3' />
              {intl.formatMessage({ id: 'b2bOrders.createNewOrder' })}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className='p-4' onClick={onCreateOrder}>
                <span className='fa fa fa-list me-3' />
                {intl.formatMessage({id: 'b2bOrders.createOrderFromScratch'})}
              </Dropdown.Item>
              <Dropdown.Item className='p-4' onClick={onParseEdi}>
                <span className='fa fa-paste me-3' />
                {intl.formatMessage({ id: 'b2bOrders.createOrderFromEDI' })}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='row mb-8'>{children}</div>
        {loading && <div className='spinner-border text-primary' role='status' />}
      
        {!loading && displayBulkActions && (
          <section className='d-flex px-0 gap-4 align-items-end mb-8'>
            <div className='me-4'>
              <label className={clsx('mb-2', !selectedCount && 'text-muted')}>
                {selectedCount > 0 ? (
                  <>
                    {intl.formatMessage(
                      { id: 'operationOrders.countSelectedOrders' },
                      { count: selectedCount }
                    )}
                  </>
                ) : (
                  <>{intl.formatMessage({ id: 'operationOrders.noSelectedOrders' })}</>
                )}
              </label>
              <Dropdown>
                <Dropdown.Toggle variant='primary' disabled={selectedCount < 1}>
                  <span className='me-4 fas w-15px d-inline-block fa-layer-group' />
                  {intl.formatMessage({ id: 'operationOrders.bulkActions' })}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onGenerateWarehouseOperationsSheet}>
                    <span className='me-4 fas w-15px d-inline-block fa-list text-muted' />
                    {intl.formatMessage({ id: 'operationOrders.generateWarehouseOperationsSheet' })}
                  </Dropdown.Item>
                  <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onGenerateProductsNecessitySheet}>
                    <span className='me-4 fas w-15px d-inline-block fa-list-alt text-muted' />
                    {intl.formatMessage({ id: 'operationOrders.generateProductsNecessitySheet' })}
                  </Dropdown.Item>
                  <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onPrintTags}>
                    <span className='me-4 fas w-15px d-inline-block fa-file text-muted' />
                    {intl.formatMessage({ id: 'operationOrders.printTags' })}
                  </Dropdown.Item>
                  <Dropdown.Item className='py-3 ps-5 fs-6 fw-bolder' onClick={onPrepareOrders}>
                    <span className='me-4 fas w-15px d-inline-block fa-magic' />
                    {intl.formatMessage({ id: 'operationOrders.prepareOrders' })}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </section>
        )}
        {!loading && orders.length === 0 && (
          <p className='fw-bolder'>{intl.formatMessage({ id: 'b2bOrders.noOrders' })}</p>
        )}
        {!loading && orders.length > 0 && (
          <div
            className='table-responsive'
            style={{
              height: 'calc(100vh - 420px)',
            }}
          >
            <p className='text-muted mb-4'>
              {intl.formatMessage(
                { id: 'b2bOrders.countOrders' },
                { visible: orders.length, count }
              )}
            </p>
            <table className='table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
              <thead className='table-header sticky-top bg-white'>
                <tr className='fw-bolder'>
                  {displayBulkActions && (
                    <th className='text-center ps-6 pe-4'>
                      <input
                        type='checkbox'
                        className='cursor-pointer form-check w-25px h-25px'
                        disabled={bulkState === 'loading'}
                        checked={selectedOrders.length === orders.length}
                        onChange={() => {
                          if (selectedOrders.length === orders.length) {
                            setSelectedOrders([])
                          } else {
                            setSelectedOrders(orders)
                          }
                        }}
                      />
                    </th>
                  )}
                  <th>{intl.formatMessage({ id: 'b2bOrders.order' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bOrders.externalReference' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bOrders.distributor' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bOrders.promiseDate' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bOrders.lines' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bOrders.comments' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bOrders.shippingFee' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bOrders.totalDiscounts' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bOrders.totalWithTaxes' })}</th>
                  <th>{intl.formatMessage({ id: 'b2bOrders.totalWeight' })}</th>
                  <th>€/kg</th>
                  <th>
                    {
                      // Empty th for the actions
                    }
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders
                  ?.sort((a, b) => b.id - a.id)
                  ?.map((order) => (
                    <tr key={order.id}>
                      {displayBulkActions && (
                        <td className='text-center ps-6 pe-4'>
                          <input
                            type='checkbox'
                            className='cursor-pointer form-check w-25px h-25px'
                            checked={selectedOrders.includes(order)}
                            disabled={bulkState === 'loading'}
                            onChange={() => {
                              if (selectedOrders.includes(order)) {
                                setSelectedOrders(selectedOrders.filter((t) => t.id !== order.id))
                              } else {
                                setSelectedOrders([...selectedOrders, order])
                              }
                            }}
                          />
                        </td>
                      )}
                      <td className='text-nowrap'>
                        <div className='mb-1'>{order.code}</div>
                        {STATE_TRANSITIONS[order.state]?.length > 0 ? (
                          <Dropdown>
                            <Dropdown.Toggle
                              id='dropdown-create-b2b-order'
                              variant={clsx({
                                primary: NEUTRAL_STATES.includes(order.state),
                                success: SUCCESS_STATES.includes(order.state),
                                danger: FAIL_STATES.includes(order.state),
                              })}
                              className={clsx({
                                'badge badge-sm px-4 py-2 mb-1': true,
                              })}
                            >
                              {order.state}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {STATE_TRANSITIONS[order.state]?.map((state) => (
                                <Dropdown.Item
                                  className='p-4'
                                  key={state}
                                  onClick={() => onUpdateState(order, state)}
                                >
                                  {state}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          <div
                            style={{
                              width: 'fit-content',
                            }}
                            className={clsx({
                              'badge badge-sm px-4 py-2 d-block mb-1': true,
                              'badge-success': SUCCESS_STATES.includes(order.state),
                              'badge-danger': FAIL_STATES.includes(order.state),
                            })}
                          >
                            {order.state}
                          </div>
                        )}
                        <div className="badge badge-dark px-4 py-2">
                          {
                            order.is_sample ?
                              <>
                                <span className="fas fa-gift me-2" />
                                {intl.formatMessage({ id: 'b2bOrders.sampleOrder' })}
                              </>
                              : <>
                                <span className="fas fa-money-bill-wave me-2" />
                                {intl.formatMessage({ id: 'b2bOrders.regularOrder' })}
                              </>
                          }
                        </div>
                      </td>
                      <td className='text-center text-nowrap'>
                        {order.external_reference || '-'}
                      </td>
                      <td>
                        <a href={`/distributor/${order.distributor}`}>
                          {distributors.find((d) => d.id === order.distributor)?.name || '-'}
                        </a>
                      </td>
                      <td className=''>
                        {order.promise_date
                          ? intl.formatDate(new Date(order.promise_date), {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })
                          : '-'}
                      </td>
                      <td className='cursor-pointer' onClick={() => onEditLines(order)}>
                        <div className='d-flex align-items-center'>
                          {order?.order_lines?.length ? (
                            <ul className='list-unstyled d-block my-0 w-200px'>
                              {order.order_lines
                                .sort((a, b) => a?.name?.localeCompare(b?.name))
                                .map((line) => (
                                  <li key={line.id}>
                                    <div className=''>
                                      <strong>{line.units}</strong> x {line?.name}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          ) : (
                            <div className='w-100 text-muted'>
                              {intl.formatMessage({ id: 'b2bOrders.noLines' })}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className='cursor-pointer' onClick={() => onEditComments(order)}>
                        {order?.comments?.length ? (
                          <p>{order?.comments}</p>
                        ) : (
                          <p className='text-muted'>-</p>
                        )}
                      </td>
                      <td>
                        {intl.formatNumber(order.shipping_fee, {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </td>
                      <td>
                        {intl.formatNumber(order.total_discounts, {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </td>
                      <td>
                        {intl.formatNumber(order.total_with_taxes, {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </td>
                      <td className='text-nowrap'>
                        {
                          order?.total_kg > -1?
                            `${intl.formatNumber(order.total_kg, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} kg`
                            : '-'
                        }
                      </td>
                      <td>
                        <span className='text-nowrap'>
                        {
                          order.euro_kg ? 
                            `${intl.formatNumber(
                              order.euro_kg, 
                              { 
                                minimumFractionDigits: 4, 
                                maximumFractionDigits: 4,
                              }
                            )} €`
                            : '-'
                        }
                        </span>
                      </td>
                      <td className='text-center'>
                        <Dropdown>
                          <Dropdown.Toggle className='btn btn-sm btn-secondary'>
                            {intl.formatMessage({ id: 'b2bOrders.actions' })}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item className='p-4' onClick={() => onEditOrder(order)}>
                              <span className='fa fa-edit me-3'></span>
                              {intl.formatMessage({ id: 'b2bOrders.edit' })}
                            </Dropdown.Item>
                            <Dropdown.Item className='p-4' onClick={() => onEditLines(order)}>
                              <span className='fa fa-list me-3'></span>
                              {intl.formatMessage({ id: 'b2bOrders.editLines' })}
                            </Dropdown.Item>
                            <Dropdown.Item className='p-4' onClick={() => onEditComments(order)}>
                              <span className='fa fa-comment me-3'></span>
                              {intl.formatMessage({ id: 'b2bOrders.comment' })}
                            </Dropdown.Item>
                            <Dropdown.Item className='p-4' onClick={() => onGenerateProforma(order)}>
                              <span className='fa fa-file-invoice me-3'></span>
                              {intl.formatMessage({ id: 'b2bOrders.generateProforma' })}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className='p-4'
                              onClick={() => onGenerateDeliveryNote(order)}
                            >
                              <span className='fa fa-file-alt me-3'></span>
                              {intl.formatMessage({ id: 'b2bOrders.generateDeliveryNote' })}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {count > 0 && (
            <>
              <Pagination
                className='mx-auto mt-4 d-flex justify-content-center'
                count={Math.ceil(count / ORDERS_PER_PAGE)}
                page={page}
                onChange={(_, page) => onPageChange(page)}
              />
            </>
          )}
          </div>
        )}
      </div>
    </>
  )
}
