/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'

import {PageTitle} from '../../../_metronic/layout/core'
import DistributorDetail from '../../modules/distributors/components/DistributorDetail'
import DistributorAddresses from '../../modules/distributors/components/DistributorAddresses'

type RouteParams = {
  distributorId: string
}

const DistributorDetailPage: FC = () => {
  const intl = useIntl()
  const {distributorId} = useParams<RouteParams>()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'distributorDetail.title'})}</PageTitle>
      <div className='row'>
        <div className='col-6'>
          <DistributorDetail 
            id={distributorId} 
          />
        </div>
        <div className='col-6'>
          <DistributorAddresses 
            id={distributorId} 
          />
        </div>
      </div>
    </>
  )
}

export {DistributorDetailPage}
