import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {useEffect, useState} from 'react'

import * as orderRedux from '../redux/OrderRedux'
import * as petRedux from '../../pets/redux/PetRedux'
import {authUserSelector} from '../../auth/redux/AuthRedux'
import superUsers from '../../tasks/constants/superUsers'
import {OrderListingModel} from '../models/OrderListingModel'
import {generateInvoice, updateOrder} from '../services/OrderCRUD'
import {toast} from 'react-toastify'
import PromiseDateModal from './PromiseDateModal'
import {Pagination} from '@mui/material'
import {Dropdown} from 'react-bootstrap'
import { DEFAULT_STATES, ERROR_STATES, OrderStateEnum, STATES_WITH_INVOICE, SUCCESSFUL_STATES, WARNING_STATES, WIP_STATES } from '../models/OrderModel'

const PROMISE_DATE_UPDATE_TIMEOUT = 1500

type BULK_STATE = 'loading' | 'idle'

type Props = {
  page: number
  count: number
  orders?: Array<OrderListingModel>
  emptyMessage?: string
  displayPetName?: boolean
  loading?: boolean
  children?: React.ReactNode
  displayBulkActions?: boolean
  displayResultsCount?: boolean
  onPageChange: (page: number) => void
  onRefresh: () => void
  onBulkDownloadInvoices?: (orders: Array<number>) => void
  onBulkPrintTags?: (orders: Array<number>) => void
  onBulkPrintInstructions?: (orders: Array<number>) => void
  onBulkSetToProcessing?: (orders: Array<number>) => void
  onBulkMarkAsReadyToShip?: (orders: Array<number>) => void
  onBulkGeneratePickingSheet?: (orders: Array<number>) => void
  onBulkGenerateWarehouseOperationsSheet?: (orders: Array<number>) => void
  onBulkGenerateProductsNecessitySheet?: (orderIds: Array<number>) => void
}

export default function OrderTable({
  page = 1,
  count = 0,
  orders = null,
  emptyMessage = '',
  displayPetName = false,
  loading = false,
  children,
  displayBulkActions = false,
  displayResultsCount = false,
  onPageChange,
  onRefresh,
  onBulkPrintTags,
  onBulkPrintInstructions,
  onBulkSetToProcessing,
  onBulkMarkAsReadyToShip,
  onBulkGeneratePickingSheet,
  onBulkGenerateWarehouseOperationsSheet,
  onBulkDownloadInvoices,
  onBulkGenerateProductsNecessitySheet,
}: Props) {
  const intl = useIntl()
  const pets = useSelector(petRedux.petsSelector, shallowEqual)
  const authUser = useSelector(authUserSelector, shallowEqual)
  const [promiseDateModalVisible, setPromiseDateModalVisible] = useState(false)
  const [editingPromiseDateOrder, setEditingPromiseDateOrder] = useState(null)
  const [loadingPromiseDateOrder, setLoadingPromiseDateOrder] = useState(null)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [bulkState, setBulkState] = useState<BULK_STATE>('idle')
  const hasSelectedAllOrders = selectedOrders?.length === orders?.length
  const selectedCount = hasSelectedAllOrders ? count : selectedOrders.length
  const [loadingOrderInvoice, setLoadingOrderInvoice] = useState<number | null>(null)

  function isPromiseDateEditable(order: OrderListingModel) {
    if (['proposal', 'confirmed'].includes(order.state)) {
      return true
    }
    return order.state === 'locked' && superUsers.includes(authUser?.email)
  }

  async function onPromiseDateClick(order) {
    setEditingPromiseDateOrder(order)
    setPromiseDateModalVisible(true)
  }

  async function onPromiseDateSubmit(promiseDate) {
    setPromiseDateModalVisible(false)
    setLoadingPromiseDateOrder(editingPromiseDateOrder)
    try {
      await updateOrder(editingPromiseDateOrder.id, {
        promise_date: promiseDate,
      })
      onRefresh()
      await new Promise((resolve) => setTimeout(resolve, PROMISE_DATE_UPDATE_TIMEOUT))
      toast.success(intl.formatMessage({id: 'orders.promiseDateUpdated'}))
    } catch (error) {
      if (error?.response?.data?.promise_date?.promise_date[0]) {
        toast.error(error?.response?.data?.promise_date?.promise_date[0])
      }
    } finally {
      setLoadingPromiseDateOrder(null)
      setEditingPromiseDateOrder(null)
    }
  }

  function getSummary(order) {
    return order?.lines
      ?.sort((a, b) =>
        a?.product?.product?.related_meal?.name.localeCompare(
          b?.product?.product?.related_meal?.name
        )
      )
      .map((line, i) => (
        <>
          {line.kind === 'product' && (
            <li key={i}>
              <strong className='text-center me-1 d-inline-block'>{line.units}</strong>x{' '}
              {line.product.product.name || line?.product?.product?.related_meal?.name || '-'}
              {line?.product?.traits && (
                <span className='text-muted ms-2 fw-bold'>({line?.product?.traits})</span>
              )}
            </li>
          )}
        </>
      ))
  }

  function getTextSummary(order) {
    return order?.lines
      ?.sort((a, b) =>
        a?.product?.product?.related_meal?.name.localeCompare(
          b?.product?.product?.related_meal?.name
        )
      )
      ?.filter((line) => line.kind === 'product')
      ?.map((line) => {
        return `${line.units} x ${
          line.product.product.name || line?.product?.product?.related_meal?.name || '-'
        } ${line?.product?.traits && `(${line?.product?.traits})`}`
      })
      ?.join('\r\n')
  }

  async function copyToClipboard(text) {
    try {
      if (navigator?.clipboard) {
        navigator.clipboard.writeText(text)
      } else {
        var textField = document.createElement('textarea')
        textField.textContent = text
        document.body.appendChild(textField)
        textField.select()
        document?.execCommand('copy')
        textField.remove()
      }
      toast.success(intl.formatMessage({id: 'orders.copiedToClipboard'}))
    } catch (error) {
      console.warn({error})
      toast.error(intl.formatMessage({id: 'orders.copyToClipboardError'}))
    }
  }

  useEffect(() => {
    setSelectedOrders([])
  }, [orders])

  async function onPrintTags() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        {id: 'orders.bulkPrintTagsConfirmation'},
        {
          count: selectedCount,
        }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkPrintTags([])
      } else {
        await onBulkPrintTags(selectedOrders?.map((order) => order.id))
      }
      toast.success(intl.formatMessage({id: 'orders.bulkPrintTagsSuccess'}, {count: selectedCount}))
    } catch (error) {
      console.warn({error})
      toast.error(intl.formatMessage({id: 'orders.bulkPrintTagsError'}))
    } finally {
      setBulkState('idle')
    }
  }

  async function onPrintInstructions() {
    const hasConfirmed = window.confirm(
      intl.formatMessage({id: 'orders.bulkPrintInstructionsConfirmation'}, {count: selectedCount})
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkPrintInstructions([])
      } else {
        await onBulkPrintInstructions(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage({id: 'orders.bulkPrintInstructionsSuccess'}, {count: selectedCount})
      )
    } catch (error) {
      console.warn({error})
      toast.error(intl.formatMessage({id: 'orders.bulkPrintInstructionsError'}))
    } finally {
      setBulkState('idle')
    }
  }

  async function onSetToProcessing() {
    const hasConfirmed = window.confirm(
      intl.formatMessage({id: 'orders.bulkSetToProcessingConfirmation'}, {count: selectedCount})
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkSetToProcessing([])
      } else {
        await onBulkSetToProcessing(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage({id: 'orders.bulkSetToProcessingSuccess'}, {count: selectedCount})
      )
    } catch (error) {
      console.warn({error})
      toast.error(intl.formatMessage({id: 'orders.bulkSetToProcessingError'}))
    } finally {
      setBulkState('idle')
    }
  }

  async function onMarkAsReadyToShip() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        {id: 'operationOrders.bulkMarkAsReadyToShipConfirmation'},
        {count: selectedCount}
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkMarkAsReadyToShip([])
      } else {
        await onBulkMarkAsReadyToShip(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage(
          {id: 'operationOrders.bulkMarkAsReadyToShipSuccess'},
          {count: selectedCount}
        )
      )
    } catch (error) {
      console.warn({error})
      toast.error(intl.formatMessage({id: 'operationOrders.bulkMarkAsReadyToShipError'}))
    } finally {
      setBulkState('idle')
    }
  }

  async function onGeneratePickingSheet() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        {id: 'operationOrders.bulkGeneratePickingSheetConfirmation'},
        {count: selectedCount}
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkGeneratePickingSheet([])
      } else {
        await onBulkGeneratePickingSheet(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage(
          {id: 'operationOrders.bulkGeneratePickingSheetSuccess'},
          {count: selectedCount}
        )
      )
    } catch (error) {
      console.warn({error})
      toast.error(intl.formatMessage({id: 'operationOrders.bulkGeneratePickingSheetError'}))
    } finally {
      setBulkState('idle')
    }
  }

  async function onGenerateWarehouseOperationsSheet() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        {id: 'operationOrders.bulkGenerateWarehouseOperationsSheetConfirmation'},
        {count: selectedCount}
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkGenerateWarehouseOperationsSheet([])
      } else {
        await onBulkGenerateWarehouseOperationsSheet(selectedOrders?.map((order) => order.id))
      }
      toast.success(
        intl.formatMessage(
          {id: 'operationOrders.bulkGenerateWarehouseOperationsSheetSuccess'},
          {count: selectedCount}
        )
      )
    } catch (error) {
      console.warn({error})
      toast.error(
        intl.formatMessage({id: 'operationOrders.bulkGenerateWarehouseOperationsSheetError'})
      )
    } finally {
      setBulkState('idle')
    }
  }

  async function onGenerateProductsNecessitySheet() {
    const hasConfirmed = window.confirm(
      intl.formatMessage(
        { id: 'operationOrders.bulkGenerateProductsNecessitySheetConfirmation' },
        { count: selectedCount }
      )
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkGenerateProductsNecessitySheet([])
      } else {
        await onBulkGenerateProductsNecessitySheet(selectedOrders?.map((order) => order.id))
      }

      toast.success(
        intl.formatMessage(
          { id: 'operationOrders.bulkGenerateProductsNecessitySheetSuccess' },
          { count: selectedCount }
        )
      )
    } catch (error) {
      console.warn({ error })
      toast.error(
        intl.formatMessage({ id: 'operationOrders.bulkGenerateProductsNecessitySheetError' })
      )
    } finally {
      setBulkState('idle')
    }
  }

  async function onGenerateInvoice(order) {
    try {
      setLoadingOrderInvoice(order.id)
      
      if (order?.invoice_url?.length > 0) {
        window.open(order.invoice_url, '_blank')
        toast.success(intl.formatMessage({id: 'operationOrders.invoiceGenerated'}))
        return;
      }

      const { data } = await generateInvoice(order.id)
      
      if (data?.pdf_file_url) {
        window.open(data.pdf_file_url, '_blank')
        toast.success(intl.formatMessage({id: 'operationOrders.invoiceGenerated'}))
      } else {
        throw new Error('No PDF file URL returned')
      }
      
    } catch (error) {
      console.warn({error})
      toast.error(intl.formatMessage({id: 'operationOrders.invoiceError'}))
    } finally {
      setLoadingOrderInvoice(null)
    }
  }

  async function onDownloadInvoices() {
    const hasConfirmed = window.confirm(
      intl.formatMessage({id: 'operationOrders.bulkDownloadInvoicesConfirmation'}, {count: selectedCount})
    )
    if (!hasConfirmed) {
      return
    }
    setBulkState('loading')
    try {
      if (hasSelectedAllOrders) {
        await onBulkDownloadInvoices([])
      } else {
        await onBulkDownloadInvoices(selectedOrders?.map((order) => order.id))
      }
      toast.success(intl.formatMessage({id: 'operationOrders.bulkDownloadInvoicesSuccess'}, {count: selectedCount}))
    } catch (error) {
      console.warn({error})
      toast.error(intl.formatMessage({id: 'operationOrders.bulkDownloadInvoicesError'}))
    } finally {
      setBulkState('idle')
    }
  }

  return (
    <>
      <div className='row mb-8'>
        {children}
        {!loading && count > 0 && displayResultsCount && (
          <div className='text-muted mt-4 mb-0'>
            {intl.formatMessage({id: 'common.results'}, {count})}
          </div>
        )}
      </div>
      {displayBulkActions && (
        <section className='d-flex px-0 gap-4 align-items-end mb-8'>
          <div className='me-4'>
            <label className={clsx('mb-2', !selectedCount && 'text-muted')}>
              {selectedCount > 0 ? (
                <>
                  {intl.formatMessage(
                    {id: 'operationOrders.countSelectedOrders'},
                    {count: selectedCount}
                  )}
                </>
              ) : (
                <>{intl.formatMessage({id: 'operationOrders.noSelectedOrders'})}</>
              )}
            </label>
            <Dropdown>
              <Dropdown.Toggle variant='primary' disabled={selectedCount < 1}>
                <span className='me-4 fas fa-layer-group' />
                {intl.formatMessage({id: 'operationOrders.bulkActions'})}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                { onBulkGeneratePickingSheet && <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onGeneratePickingSheet}>
                  <span className='me-4 fas fa-file-alt text-muted' />
                  {intl.formatMessage({id: 'operationOrders.generatePickingSheet'})}
                </Dropdown.Item> }
                { onBulkGenerateWarehouseOperationsSheet && <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onGenerateWarehouseOperationsSheet}>
                  <span className='me-4 fas fa-file text-muted' />
                  {intl.formatMessage({id: 'operationOrders.generateWarehouseOperationsSheet'})}
                </Dropdown.Item>}
                {
                  onBulkGenerateProductsNecessitySheet && <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onGenerateProductsNecessitySheet}>
                    <span className='me-4 fas w-15px d-inline-block fa-list-alt text-muted' />
                    {intl.formatMessage({ id: 'operationOrders.generateProductsNecessitySheet' })}
                  </Dropdown.Item>
                }
                { onBulkPrintTags && <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onPrintTags}>
                  <span className='me-4 fas fa-tag text-muted' />
                  {intl.formatMessage({id: 'operationOrders.printTags'})}
                </Dropdown.Item>}
                { onBulkPrintInstructions && <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onPrintInstructions}>
                  <span className='me-4 fas fa-table text-muted' />
                  {intl.formatMessage({id: 'operationOrders.printInstructions'})}
                </Dropdown.Item> }
                { onBulkSetToProcessing && <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onSetToProcessing}>
                  <span className='me-4 fas fa-dolly text-muted' />
                  {intl.formatMessage({id: 'operationOrders.setStateToProcessing'})}
                </Dropdown.Item>}
                { onBulkMarkAsReadyToShip && <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onMarkAsReadyToShip}>
                  <span className='me-4 fas fa-truck text-muted' />
                  {intl.formatMessage({id: 'operationOrders.markAsReadyToShip'})}
                </Dropdown.Item> }
                { onBulkDownloadInvoices && <Dropdown.Item className='py-3 ps-5 fs-6' onClick={onDownloadInvoices}>
                  <span className='me-4 fas fa-file-invoice text-muted' />
                  {intl.formatMessage({id: 'operationOrders.downloadInvoices'})}
                </Dropdown.Item>}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <button className='btn btn-primary' onClick={onRefresh}>
              <span className='me-4 fas fa-sync' />
              {intl.formatMessage({id: 'operationOrders.refresh'})}
            </button>
          </div>
        </section>
      )}
      {loading && <div className='spinner-border text-primary' role='status' />}
      {!loading && !orders.length && <p>{emptyMessage}</p>}
      {!loading && orders.length > 0 && (
        <div className='mt-0 table-responsive'>
          <table className='table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
            <thead className='table-header sticky-top bg-white'>
              <tr className='fw-bolder align-bottom'>
                {displayBulkActions && (
                  <th className='text-center ps-6 pe-4'>
                    <input
                      type='checkbox'
                      className='cursor-pointer form-check w-25px h-25px'
                      disabled={bulkState === 'loading'}
                      checked={selectedOrders.length === orders.length}
                      onChange={() => {
                        if (selectedOrders.length === orders.length) {
                          setSelectedOrders([])
                        } else {
                          setSelectedOrders(orders)
                        }
                      }}
                    />
                  </th>
                )}
                <th className=''>{intl.formatMessage({id: 'orders.order'})}</th>                
                {displayPetName && <th className=''>{intl.formatMessage({id: 'orders.pet'})}</th>}
                <th className=' text-center w-60px'>
                  {intl.formatMessage({id: 'orders.totalPrice'})}
                </th>
                <th className=''>{intl.formatMessage({id: 'orders.summary'})}</th>
                <th className='text-center text-nowrap'>
                  {intl.formatMessage({id: 'orders.dates'})}
                </th>
                <th className=' text-center'>{intl.formatMessage({id: 'orders.combined'})}</th>
                <th className='w-50px text-center'>{intl.formatMessage({id: 'orders.days'})}</th>
                <th className=' w-50px text-center'>
                  {intl.formatMessage({id: 'orders.isTrial'})}
                </th>
                <th className=' w-80px text-center'>
                  {intl.formatMessage({id: 'orders.foodWeight'})} &{' '}
                  {intl.formatMessage({id: 'orders.foodCalories'})}
                </th>
                <th className=' text-center'>{intl.formatMessage({id: 'orders.coupons'})}</th>
                <th className=' text-end w-80px'>{intl.formatMessage({id: 'orders.gm'})}</th>
                <th className='text-end'>{intl.formatMessage({ id: 'orders.invoice'})}</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order: OrderListingModel) => (
                <tr key={order.id} className='h-60px text-nowrap'>
                  {displayBulkActions && (
                    <td className='text-center ps-6 pe-4'>
                      <input
                        type='checkbox'
                        className='cursor-pointer form-check w-25px h-25px'
                        checked={selectedOrders.includes(order)}
                        disabled={bulkState === 'loading'}
                        onChange={() => {
                          if (selectedOrders.includes(order)) {
                            setSelectedOrders(selectedOrders.filter((t) => t.id !== order.id))
                          } else {
                            setSelectedOrders([...selectedOrders, order])
                          }
                        }}
                      />
                    </td>
                  )}
                  <td className=' '>
                    <div className='d-flex flex-column align-items-start'>
                      <Link
                        to={`/user/${order?.user_id}/order/${order?.id}`}
                        className='d-block link mb-1'
                      >
                        {order?.code}
                      </Link>
                      <div
                        className={clsx(
                          'badge',
                          SUCCESSFUL_STATES.includes(order?.state) && 'badge-success',
                          WIP_STATES.includes(order?.state) && 'badge-dark',
                          DEFAULT_STATES.includes(order?.state) && 'badge-primary',
                          WARNING_STATES.includes(order?.state) && 'badge-light-primary',
                          ERROR_STATES.includes(order?.state) && 'badge-danger'
                        )}
                      >
                        {order.state}
                      </div>
                    </div>
                    <div className='badge badge-dark text-light mt-1'>
                        {order?.is_reship && <>
                          <span className="fas fa-redo me-2 text-light" />
                          {intl.formatMessage({id: 'operationOrders.reship'})}
                        </>}
                        {order?.is_merged && <>
                          <span className="fas fa-dolly me-2 text-light" />
                          {intl.formatMessage({id: 'operationOrders.merged'})}
                        </>}
                        {order?.is_merged_parent && <>
                          <span className="fas fa-boxes me-2 text-light" />
                          {intl.formatMessage({id: 'operationOrders.mergedParent'})}
                        </>}
                      </div>
                  </td>
                  {displayPetName && (
                    <td className=''>
                      <Link
                        to={`/user/${order?.user_id}/pet/${
                          pets.find((pet) => pet.id === order.pet_id)?.id
                        }`}
                        className='link cursor-pointer'
                      >
                        {pets.find((pet) => pet.id === order.pet_id)?.name}
                      </Link>
                    </td>
                  )}
                  <td className=' text-end'>
                    {intl.formatNumber(order.total_price_inc_tax, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                  <td className='position-relative'>
                    <ul
                      className='list-unstyled m-0 cursor-pointer'
                      onClick={() => copyToClipboard(getTextSummary(order))}
                    >
                      {getSummary(order)}
                    </ul>
                  </td>
                  <td className='text-center'>
                    <span className='text-muted fw-bold fs-8 d-block mb-0'>
                      {intl.formatMessage({id: 'orders.promise'})}
                    </span>
                    {isPromiseDateEditable(order) && loadingPromiseDateOrder?.id !== order.id && (
                      <button
                        style={{
                          cursor: 'pointer',
                          width: 'fit-content',
                        }}
                        onClick={() => onPromiseDateClick(order)}
                        className='d-flex bg-white justify-content-between align-items-center border border-secondary px-4 py-3 rounded fs-7 fw-bold mx-auto'
                      >
                        <span className='fa fa-edit d-inline me-3' />
                        {order.promise_date
                          ? new Date(order.promise_date).toLocaleDateString()
                          : '-'}
                      </button>
                    )}
                    {isPromiseDateEditable(order) && loadingPromiseDateOrder?.id === order.id && (
                      <div className='spinner-border text-primary' role='status' />
                    )}
                    {!isPromiseDateEditable(order) && (
                      <div
                        style={{
                          cursor: 'not-allowed',
                          width: 'fit-content',
                        }}
                        className='d-flex bg-white justify-content-between align-items-center border border-secondary px-4 py-3 rounded fs-7 fw-bold mx-auto'
                      >
                        <span className='fa fa-lock d-inline me-3 text-muted' />
                        {order.promise_date
                          ? new Date(order.promise_date).toLocaleDateString()
                          : '-'}
                      </div>
                    )}
                    <span className='text-muted fw-bold fs-8 d-block mt-2 mb-0'>
                      {intl.formatMessage({id: 'orders.charge'})}
                    </span>
                    <div
                      style={{width: 'fit-content'}}
                      className='d-flex bg-white justify-content-between align-items-center border border-secondary px-4 py-3 rounded fs-7 fw-bold mx-auto mb-4'
                    >
                      <span className='fa fa-lock d-inline me-3 text-muted' />
                      {order.charge_date ? new Date(order.charge_date).toLocaleDateString() : '-'}
                    </div>
                  </td>
                  <td className=' text-center'>
                    {order.combine
                      ? intl.formatMessage({id: 'orders.yes'})
                      : intl.formatMessage({id: 'orders.no'})}
                  </td>
                  <td className=' text-center'>{order.days}</td>
                  <td className=' text-center'>
                    {order.is_trial
                      ? intl.formatMessage({id: 'orders.yes'})
                      : intl.formatMessage({id: 'orders.no'})}
                  </td>
                  <td className=' text-center '>
                    <div>
                      {order.total_weight_in_grs ? <>{order.total_weight_in_grs} gr</> : '-'}
                    </div>
                    <div>
                      {order.total_calories ? <>{Math.round(order.total_calories)} kcal</> : '-'}
                    </div>
                  </td>
                  <td className='py-4 overflow-hidden'>
                    {order.coupons?.length > 0 ? (
                      <details>
                        <summary className='text-primary'>
                          {intl.formatMessage({id: 'orders.seeCoupons'})}
                        </summary>
                        <ul className='mt-2 px-5'>
                          {order.coupons.map((coupon) => (
                            <li className='mb-2 text-wrap' key={coupon.discount.code}>
                              {coupon.discount.name} (
                              {coupon.discount.mode === 'absolute'
                                ? `${coupon.discount.discount}€`
                                : `${Math.floor(coupon.discount.discount * 100)}%`}
                              )
                            </li>
                          ))}
                        </ul>
                      </details>
                    ) : (
                      <div className='text-center'>-</div>
                    )}
                  </td>
                  <td className=' text-end'>
                    {(order.gross_margin_ratio * 100).toFixed(2)} %
                  </td>
                  <td className=' text-center'>
                    {
                      STATES_WITH_INVOICE.includes(order.state as OrderStateEnum) ? 
                        <button 
                          className='btn btn-text text-primary mt-0 pt-0' 
                          disabled={!!loadingOrderInvoice}
                          onClick={() => onGenerateInvoice(order)}>
                          {
                            loadingOrderInvoice === order.id ? <div className='spinner-border text-primary' role='status' /> : 
                            <span className='me-2 fas fa-file-invoice' />
                          }
                        </button>
                        : '-'
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {count > 0 && (
            <>
              <Pagination
                className='mx-auto mt-4 d-flex justify-content-center'
                count={Math.ceil(count / orderRedux.ORDERS_PER_PAGE)}
                page={page}
                onChange={(_, page) => onPageChange(page)}
              />
            </>
          )}
        </div>
      )}
      <PromiseDateModal
        isOpen={promiseDateModalVisible}
        onClose={() => setPromiseDateModalVisible(false)}
        onSubmit={onPromiseDateSubmit}
        initialValue={editingPromiseDateOrder?.promise_date}
      />
    </>
  )
}
