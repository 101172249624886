import axios from 'axios'

import BusinessDashboardModel from '../models/BusinessDashboardModel'
import KpiDetailModel from '../models/KpiDetailModel'
import { OrderStateEnum } from '../../orders'

const API_URL = process.env.REACT_APP_API_URL

export function getBusinessDashboard({
  since = null,
  until = null,
}): Promise<{data: BusinessDashboardModel}> {
  const endpoint = `${API_URL}/api/bi/dashboard/`
  const params = {
    since_data: since,
    until_data: until,
  }
  return axios.get(endpoint, {params})
}

interface Props {
  since: string
  until: string
  agent?: number
  kind?: 'rescate' | 'retention' | 'subscription' | 'trials'
}

export function getAtcDashboard({
  since = null,
  until = null,
  agent = null,
  kind = null,
}: Props): Promise<{data: BusinessDashboardModel}> {
  const endpoint = `${API_URL}/api/bi/dashboard-atc/`
  const params = {
    since_data: since,
    until_data: until,
  }
  if (agent) {
    params['agent'] = agent
  }
  if (kind) {
    params['kind'] = kind
  }
  return axios.get(endpoint, {params})
}

export function getKpiDetails({id}): Promise<{data: Array<KpiDetailModel>}> {
  const endpoint = `${API_URL}/api/bi/${id}/details/`

  return axios.get(endpoint)
}

export function sendSalesExcel({ since, until }) {
  const endpoint = `${API_URL}/api/bi/send-sales-excel/`
  const params = {
    promise_date__gte: since,
    promise_date__lte: until,
  }
  
  return axios.post(endpoint, params)
}

interface SendOrdersExcelProps {
  since: string
  until: string
  states: Array<OrderStateEnum>
}
export function sendOrdersExcel({ since, until, states }: SendOrdersExcelProps) {
  const endpoint = `${API_URL}/api/bi/send-orders-excel/`
  const params = {
    promise_date__gte: since,
    promise_date__lte: until,
    states,
  }
  
  return axios.post(endpoint, params)
}