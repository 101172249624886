import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { staffUsersSelector } from '../../tasks'

export default function LeadDistributorList({
  leadDistributors = [],
  loading = false,
  onAddLeadDistributor,
  children,
}) {
  const staffUsers = useSelector(staffUsersSelector)
  let history = useHistory()
  const intl = useIntl()

  const onClick = (e, distributorId) => {
    const target = e.target
    if (target.tagName === 'BUTTON' || target.tagName === 'A') {
      return
    }
    history.push(`/lead-distributor/${distributorId}/`)
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-start mb-8'>
        <h2 className=''>{intl.formatMessage({ id: 'leadDistributors.listTitle' })}</h2>
        <button onClick={onAddLeadDistributor} className='btn btn-primary'>
          <span className='fa fa-plus me-2' />
          {intl.formatMessage({ id: 'leadDistributors.addNewLeadDistributor' })}
        </button>
      </div>
      <div>
        {children}
      </div>
      {loading && <div className='spinner-border text-primary' role='status' />}
      {!loading && leadDistributors.length === 0 && (
        <p>{intl.formatMessage({ id: 'leadDistributors.noLeadDistributors' })}</p>
      )}
      {!loading && leadDistributors.length > 0 && (
        <div className='table-responsive'
          style={{
            height: 'calc(100vh - 420px)',
          }}>
          <table className='table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
            <thead className='table-header sticky-top bg-white'>
              <tr className=''>
                <th className='ps-2'>{intl.formatMessage({ id: 'leadDistributors.id' })}</th>
                <th>{intl.formatMessage({ id: 'leadDistributors.name' })}</th>
                <th>{intl.formatMessage({ id: 'leadDistributors.email' })}</th>
                <th>{intl.formatMessage({ id: 'leadDistributors.phone' })}</th>
                <th>{intl.formatMessage({ id: 'leadDistributors.agent' })}</th>
                <th>{intl.formatMessage({ id: 'leadDistributors.view' })}</th>
              </tr>
            </thead>
            <tbody className='text-left'>
              {leadDistributors
                ?.map((distributor, index) => (
                  <tr
                    className='cursor-pointer'
                    key={index}
                    onClick={(e) => onClick(e, distributor.id)}
                  >
                    <td className='ps-2'>{distributor.id}</td>
                    <td>{distributor.name}</td>
                    <td>{distributor.email || '-'}</td>
                    <td>{distributor.phone || '-'}</td>
                    <td>{
                      staffUsers?.find((user) => user.id === distributor.agent)?.email || distributor.agent || '-'
                    }</td>
                    <td>
                      <Link
                        to={`/lead-distributor/${distributor.id}/`}
                        className='btn btn-sm btn-tertiary fa fa-eye text-primary py-0'
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}
