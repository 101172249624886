import axios from 'axios'
import {DistributorModel} from '../models/DistributorModel'

const API_URL = process.env.REACT_APP_API_URL
const DISTRIBUTORS_URL = `${API_URL}/api/b2b/distributor/`

export function listDistributors(state): Promise<{data: Array<DistributorModel>}> {
  return axios.get(`${DISTRIBUTORS_URL}?state=${state}`)
}

export function retrieveDistributor(id: number): Promise<{data: DistributorModel}> {
  return axios.get(`${DISTRIBUTORS_URL}${id}/`)
}

export function createDistributor(
  distributor: DistributorModel
): Promise<{data: DistributorModel}> {
  return axios.post(DISTRIBUTORS_URL, distributor)
}

export function updateDistributor(
  distributor: DistributorModel
): Promise<{data: DistributorModel}> {
  return axios.patch(`${DISTRIBUTORS_URL}${distributor.id}/`, distributor)
}
